import {
  Box, Button, Typography, darken, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { Emphasis } from '../components/Emphasis';
import './styles.css';
import { PurchaseButton } from '../components/PurchaseButton';

export default function CurrentReality() {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  return (
    <Box
      sx={{
        padding: '30px',
        paddingTop: '20px',
        width: '100vw',
        minHeight: '99vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px',
        backgroundColor: palette.primary.dark,
      }}
    >
      <Box sx={{ textAlign: 'center', color: darken(palette.success.dark, 0.8) }}>
        <Typography variant="h4" color={palette.secondary.main} mb={1}>
          <Emphasis>Parabéns pela sua decisão!</Emphasis>
        </Typography>
        <Typography variant="h5" color={palette.secondary.main}>
          Mas antes, preciso falar com você...
        </Typography>
        <Typography variant="h5" color={palette.secondary.main} mb={2}>
          Não feche essa página
        </Typography>

        <div className="container">
          <iframe
            src="https://www.youtube.com/embed/YwURNxy6-90?showinfo=0&controls=0&autohide=1&autoplay=1&modestbranding=1&rel=0&hd=1"
            title="Prospecção Ártemis"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          />
        </div>

      </Box>
      <PurchaseButton text="Sim, quero aprender a prospectar clientes" style={{ marginTop: 2, fontSize: isMobile ? '12pt' : '14pt' }} />
      <Button
        variant="text"
        sx={{
          marginTop: 2,
          borderRadius: 30,
          padding: '0 30px',
          height: 50,
          fontSize: '12pt',
          fontWeight: 'bold',
          color: palette.error.main,
          fontFamily: 'Poppins, sans-serif',
          textDecoration: 'underline',
        }}
        onClick={() => window.open('https://pay.kiwify.com.br/2JRBcci', '_blank')}
      >
        Não, vou deixar passar essa chance
      </Button>
    </Box>
  );
}
