import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';
import CurrentReality from './sections/CurrentReality';
import { Footer } from './sections/Footer';

const primary = '#F2F2F2';
const secondary = '#a67c00';

const theme = createTheme({
  typography: {
    fontFamily: ['Lora', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: primary,
      contrastText: '#141414',
      dark: '#fefefe',
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: '#CD9A72',
    },
    error: {
      main: '#8b0000',
    },
    background: {
      default: primary,
      paper: '#fefefe',
    },
    text: {
      primary: '#1b1b1b',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" style={{ height: '100vh', padding: 0, margin: 0 }}>
        <Box>
          <CurrentReality />
          <Footer />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
